import { useMutation, useQuery } from "react-query";
import { LoginRequest } from '../login-request.model';
import { authService } from '../../../services/auth.service';
import { ForgotPasswordRequest } from "../forgot-password-request.model";
import { ResetPasswordRequest } from "../reset-password-request.model";
import { User } from "../user.model";

export function useLoginMutation() {
    return useMutation((loginRequest: LoginRequest) => 
    authService.login(loginRequest));
}
export function useForgotPasswordMutation() {
    return useMutation((request: ForgotPasswordRequest) => 
    authService.forgotPassword(request));
}
export function useResetPasswordMutation() {
    return useMutation((request: ResetPasswordRequest) => 
    authService.resetPassword(request));
}
export function useGetUserByTokenQuery(token: string | undefined) {
    return useQuery<User | undefined>(
        ["authuser", token],
        () => fetchUserByToken(token),
        { refetchOnWindowFocus: false }
    );
}

async function fetchUserByToken(token: string | undefined) {
    if (!token) {
        return  undefined;
    } else {
        return await authService.getUserByToken(token);
    }
}
