import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import { SkillLevel } from "../../queries/models/skill-level.model";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import { RideType } from "../../queries/models/ride-type.model";

export interface RideTypeEditFormProps {
  rideType: RideType;
  isSubmitting?: boolean;
  onSave: (form: SkillLevel) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function RideTypeForm({
  rideType,
  onSave,
  onCancel,
  onDelete,
}: RideTypeEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const initialValues: SkillLevel = {
    id: rideType?.id ?? 0,
    name: rideType?.name ?? "",
    comment: rideType?.comment ?? "",
    order: rideType?.order ?? 0,
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    order: Yup.number().required(),
    comment: Yup.string().optional().nullable(),
  });

  const onSubmit = useCallback(
    (values: SkillLevel) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={rideType?.id ? rideType.name : "Dodaj nowy"}
                showDeleteButton={hasWriteAccess && !!rideType.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Nazwa"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputNumber
                    label="Kolejność"
                    name="order"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                    autoResize={true}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
