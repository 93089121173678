import { useState } from "react";
import { useForgotPasswordMutation } from "../../queries/models/_shared/auth.query";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/ui/toast-context-provider";

export function ForgotPassword() {
  const toast = useToast();
  const [username, setUsername] = useState<string>();

  const mutation = useForgotPasswordMutation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  function executeReset() {
    setIsLoading(true);
    mutation.mutate(
      {
        login: username!,
      },
      {
        onSuccess: (response) => {
          toast.current?.show({
            severity: "success",
            detail: "Check your mailbox for next steps.",
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        },
        onError: (error) => {
          toast.current?.show({
            severity: "error",
            detail: error?.toString(),
          });
          console.error(error);
        },
        onSettled: (x, y, z, c) => {
          setIsLoading(false);
        },
      }
    );
  }

  return (
    <div className="surface-300">
      <div className="flex justify-content-center align-items-center h-screen">
        <Card className="w-20rem border-3 border-primary border-round-2xl">
          <h2 className="mt-1 mb-4 text-center">Forgot password request</h2>
          <span className="p-float-label mb-4">
            <InputText
              id="username"
              className="w-full"
              value={username ?? ""}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? executeReset() : {})}
            />
            <label htmlFor="username">Username or email:</label>
          </span>
          <Button
            label="Send request"
            loading={isLoading}
            className="bg-primary w-full"
            onClick={executeReset}
          />
        </Card>
      </div>
    </div>
  );
}
