import { UseQueryOptions, useQuery } from 'react-query';
import { reportingService } from '../services/reporting.service';
import { RideReportItem } from './models/ride-report.model';

export function useRidesReportQuery(from: Date, to: Date) {
    const queryOptions: UseQueryOptions<RideReportItem[]> = {
        enabled: !(!from) && !(!to)
    }

    return useQuery<RideReportItem[]>(
        ["rides-report", from, to],
        () => reportingService.getRidesReport(from, to),
        queryOptions
    )
}