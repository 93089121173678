import { useState } from "react";
import { useLoginMutation } from "../../queries/models/_shared/auth.query";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "primereact/utils";
import { useToast } from "../../components/ui/toast-context-provider";

export function Login() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loginError, setLoginError] = useState<string>();

  const loginMutation = useLoginMutation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  function navigateToForgotPassword() {
    navigate("forgotpassword");
  }

  function executeLogin() {
    setIsLoading(true);
    loginMutation.mutate(
      {
        username: username!,
        password: password!,
      },
      {
        onSuccess: (response) => {
          authService.setLoggedUser({
            accessToken: response.token,
            id: response.user.id,
            isAdmin: response.user.isAdmin,
            username: response.user.username,
            email: response.user.email,
          });

          //if (response.user.isResetPasswordPending) {
          //  setResponseUser(response.user);
          //  return;
          //}
          navigate("app/plan");
        },
        onError: (error: any) => {
          if (error.status === HttpStatusCode.Conflict)
            setLoginError(error.data);

          console.error(error);
        },
        onSettled: (x, y, z, c) => {
          setIsLoading(false);
        },
      }
    );
  }

  return (
    <div className="surface-300">
      <div className="flex justify-content-center align-items-center h-screen">
        <Card className="w-20rem border-3 border-primary border-round-2xl">
          <h2 className="mt-1 text-center">Kalinowka</h2>
          <span className="p-float-label mb-4">
            <InputText
              id="username"
              className={classNames("w-full", loginError ? "p-invalid" : "")}
              value={username ?? ""}
              onChange={(e) => {
                setUsername(e.target.value);
                setLoginError(undefined);
              }}
            />
            <label
              htmlFor="username"
              className={classNames("w-full", loginError ? "text-red-500" : "")}
            >
              Login:
            </label>
          </span>
          <span className="p-float-label">
            <InputText
              id="password"
              className={classNames("w-full", loginError ? "p-invalid" : "")}
              type={"password"}
              value={password ?? ""}
              onChange={(e) => {
                setPassword(e.target.value);
                setLoginError(undefined);
              }}
              onKeyDown={(e) => (e.key === "Enter" ? executeLogin() : {})}
            />
            <label
              htmlFor="password"
              className={classNames("w-full", loginError ? "text-red-500" : "")}
            >
              Hasło:
            </label>
          </span>
          <div className="w-full text-right mb-4">
            {loginError && (
              <div
                className="w-12 h-3rem text-red-500 mt-2 px-2 py-1 border-2 border-round-sm flex"
                style={{ backgroundColor: "rgba(255,0,0,0.12)" }}
              >
                <div className="w-2rem h-full flex align-items-center text-2xl justify-content-center">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </div>
                <div className="w-full h-full flex justify-content-center align-items-center">
                  Coś nie zagrało.
                </div>
              </div>
            )}
            <div
              className="w-12 hover:text-indigo-600 cursor-pointer"
              onClick={navigateToForgotPassword}
            >
              Zapomniałeś hasła?
            </div>
          </div>
          <Button
            label="Zaloguj"
            loading={isLoading}
            className="bg-primary w-full"
            onClick={executeLogin}
          />
        </Card>
      </div>
    </div>
  );
}
