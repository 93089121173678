import { useMutation, useQuery } from 'react-query';
import { SkillLevel } from './models/skill-level.model';
import { skillLevelsService } from '../services/skill-levels.service';

export function useSkillLevelsQuery() {
    return useQuery<SkillLevel[]>(["skill-levels"], () =>
        skillLevelsService.getAllActive());
}

export function useActiveSkillLevelsAsSelectOptionQuery() {
    return useQuery(
        ["skill-levels", "skill-levels-options"],
        () => skillLevelsService.getAllActiveAsOptions()
    )
}

export function useAddSkillLevelMutation() {
    return useMutation((request: SkillLevel) => skillLevelsService.add(request));
}

export function useEditSkillLevelMutation() {
    return useMutation((request: SkillLevel) => skillLevelsService.edit(request));
}

export function useDeleteSkillLevelMutation() {
    return useMutation((id: number) => skillLevelsService.delete(id));
}