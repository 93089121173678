import { TabPanel, TabView } from "primereact/tabview";
import { SkillLevels } from "../SkillLevels/SkillLevelsPage";
import { RideTypesPage } from "../RideTypes/RideTypesPage";

export function VariablesTabs() {
  return (
    <>
      <TabView>
        <TabPanel header="Poziomy umiejętności">
          <SkillLevels />
        </TabPanel>
        <TabPanel header="Typy jazdy">
          <RideTypesPage />
        </TabPanel>
      </TabView>
    </>
  );
}
