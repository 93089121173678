import { useMutation, useQuery } from 'react-query';
import { AppSettings } from './models/appsettings.model';
import { appSettingsService } from '../services/appsettings.service';

export function useGetAppSettingsQuery() {
    return useQuery(
        ["appsettings"],
        () => appSettingsService.get()
    )
}
export function useSetAppSettingsMutation() {
    return useMutation((request: AppSettings) => appSettingsService.set(request));
}