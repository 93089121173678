import { useCallback, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { confirmDialog } from "primereact/confirmdialog";
import { phoneRegExp } from "../../utils/phoneRegExp";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { authService } from "../../services/auth.service";
import { Horse } from "../../queries/models/horse.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import { Rider } from "../../queries/models/rider.model";
import { SelectItem } from "primereact/selectitem";
import { FormInputLabel } from "../../components/ui/formik/FormInputLabel";
import { RiderHorseSkill } from "../../queries/models/rider-horse-skill.model";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useRidesByRiderQuery } from "../../queries/rides.query";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export interface RiderEditFormProps {
  rider: Rider;
  isSubmitting?: boolean;
  horseOptions: SelectItem[];
  skillOptions: SelectItem[];
  onSave: (form: Rider) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function RiderForm({
  rider,
  horseOptions,
  skillOptions,
  onSave,
  onCancel,
  onDelete,
}: RiderEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const ridesHistoryQuery = useRidesByRiderQuery(rider.id);

  const [newHorseSkill, setNewHorseSkill] = useState<any>({});

  const historyData = useMemo(() => {
    let result = ridesHistoryQuery.data?.map((x) => ({
      date: x.date,
      horse: x.horse?.name ?? "",
      user: x.user?.username ?? "",
      currentCredit: x.currentCredit ?? "",
      color: x.hasIssue ? "text-red-600" : "",
    }));

    let events =
      rider.skillEvents?.map((x) => ({
        date: x.date,
        horse: x.horse?.name ?? "",
        user: x.skill?.name ?? "",
        currentCredit: "",
        color: "text-green-600",
      })) ?? [];

    result = result?.concat(events).sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      } else if (a.date > b.date) {
        return -1;
      } else {
        return 0;
      }
    });

    return result;
  }, [ridesHistoryQuery.data, rider.skillEvents]);

  const initialValues: Rider = {
    id: rider?.id ?? 0,
    name: rider?.name ?? "",
    shortName: rider?.shortName ?? "",
    age: rider?.age ?? 0,
    credits: rider?.credits ?? 0,
    comment: rider?.comment ?? "",
    skills: rider.skills ?? [],
    useCredits: rider?.useCredits ?? false,
    rides: [],
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    shortName: Yup.string(),
    comment: Yup.string().optional().nullable(),
    age: Yup.number().required(),
    credits: Yup.number().required(),
    useCredits: Yup.boolean().required(),
    skills: Yup.array().required(),
  });

  const onSubmit = useCallback(
    (values: Rider) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column-reverse" : " flex-column"
              )}
            >
              <EditContentHeader
                header={rider?.id ? rider.name : "Dodaj nowego"}
                showDeleteButton={hasWriteAccess && !!rider.id}
                saveButtonDisabled={!hasWriteAccess || !formik.isValid}
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid mt-4">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Imię i nazwisko"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Skrót"
                    name="shortName"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputNumber
                    label="Wiek"
                    name="age"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-6 p-0 m-0">
                      <FormikInputSwitch
                        checked={false}
                        label="Używa kartentu"
                        name="useCredits"
                        validationSchema={validationSchema}
                        disabled={!hasWriteAccess}
                      />
                    </div>

                    {formik.values.useCredits && (
                      <div className="field col-6 md:col-6">
                        <FormikInputNumber
                          label="Kredyty"
                          name="credits"
                          validationSchema={validationSchema}
                          isIndependent
                          disabled={!hasWriteAccess}
                        />
                        {rider.id &&
                          rider.credits !== formik.values.credits && (
                            <span>{`Dodajesz ${
                              formik.values.credits - rider.credits
                            } kredyty.`}</span>
                          )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormInputLabel
                    nameFor={"skills"}
                    validationSchema={validationSchema}
                    forceIsRequired={false}
                  >
                    {"Umiejętności"}
                  </FormInputLabel>
                  {formik.values.skills?.map((skill) => (
                    <div key={`${skill.id}${skill.riderId}${skill.horseId}`}>
                      <div className="flex">
                        <div className="w-full m-1">
                          <Dropdown
                            className={classNames({
                              "w-full": true,
                            })}
                            type="text"
                            options={horseOptions}
                            placeholder={"Koń"}
                            value={skill.horseId}
                            onChange={(e) => {
                              const newArray = [
                                ...(formik.values.skills ?? []),
                              ];

                              const index = newArray.findIndex(
                                (x) =>
                                  x.horseId === skill.horseId &&
                                  x.skillId === skill.skillId
                              );

                              newArray[index].horseId = e.target.value;
                              formik.setFieldValue("skills", newArray);
                            }}
                          />
                        </div>
                        <div className="w-full m-1">
                          <Dropdown
                            className={classNames({
                              "w-full": true,
                            })}
                            type="text"
                            options={skillOptions}
                            placeholder={"Poziom"}
                            value={skill.skillId}
                            onChange={(e) => {
                              const newArray = [
                                ...(formik.values.skills ?? []),
                              ];

                              const index = newArray.findIndex(
                                (x) =>
                                  x.horseId === skill.horseId &&
                                  x.skillId === skill.skillId
                              );

                              newArray[index].skillId = e.target.value;
                              formik.setFieldValue("skills", newArray);
                            }}
                          />
                        </div>
                        <div className=" m-1">
                          <Button
                            className="settings-button"
                            type="button"
                            disabled={!skill.horseId || !skill.skillId}
                            icon="pi pi-trash"
                            onClick={() => {
                              const newArray = [
                                ...(formik.values.skills ?? []),
                              ];

                              const index = newArray.findIndex(
                                (x) =>
                                  x.horseId === skill.horseId &&
                                  x.skillId === skill.skillId
                              );

                              newArray.splice(index, 1);

                              formik.setFieldValue("skills", newArray);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex">
                    <div className="w-full m-1">
                      <Dropdown
                        className={classNames({
                          "w-full": true,
                        })}
                        type="text"
                        options={horseOptions}
                        placeholder={"Koń"}
                        value={newHorseSkill.horseId}
                        onChange={(e) => {
                          setNewHorseSkill({
                            horseId: e.target.value,
                            skillId: newHorseSkill.skillId,
                          });
                        }}
                      />
                    </div>
                    <div className="w-full m-1">
                      <Dropdown
                        className={classNames({
                          "w-full": true,
                        })}
                        type="text"
                        options={skillOptions}
                        placeholder={"Poziom"}
                        value={newHorseSkill.skillId}
                        onChange={(e) => {
                          setNewHorseSkill({
                            horseId: newHorseSkill.horseId,
                            skillId: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className=" m-1">
                      <Button
                        className="settings-button"
                        type="button"
                        disabled={
                          !newHorseSkill.horseId || !newHorseSkill.skillId
                        }
                        icon="pi pi-plus"
                        onClick={() => {
                          const newArray = [...(formik.values.skills ?? [])];
                          newArray.push({
                            horseId: newHorseSkill.horseId,
                            skillId: newHorseSkill.skillId,
                            riderId: rider.id,
                            comment: "",
                            id: 0,
                          });

                          formik.setFieldValue("skills", newArray);
                          setNewHorseSkill({});
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field col-12 md:col-6 p-0 m-0 mt-4">
                <FormInputLabel
                  validationSchema={validationSchema}
                  forceIsRequired={false}
                >
                  {"Historia jazd"}
                </FormInputLabel>

                <DataTable
                  value={historyData}
                  loading={ridesHistoryQuery.isFetching}
                  resizableColumns={true}
                  scrollable={false}
                  rowClassName={(x) => x.color}
                  tableStyle={{ width: "10px", overflow: "none" }}
                >
                  <Column
                    field="date"
                    header="Data"
                    sortable
                    bodyClassName={(x) => "px-1 mx-1"}
                    body={(x) => x.date?.toLocaleDateString()}
                  />
                  <Column
                    field="horse"
                    header="Koń"
                    bodyClassName={(x) => "px-1 mx-1"}
                    sortable
                  />
                  <Column
                    field="user"
                    header="Instruktor"
                    bodyClassName={(x) => "px-1 mx-1"}
                    sortable
                  />
                  <Column
                    field="currentCredit"
                    header="K"
                    bodyClassName={(x) => "px-1 mx-1"}
                  />
                </DataTable>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
