import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { AppSettings } from "../queries/models/appsettings.model";

class AppSettingsService extends BaseService {

    async get(): Promise<AppSettings> {
        const url = `${this.endpointPath}`;
        return this.http.get<AppSettings>(url).then((response: any) => response.data);
    }
    async set(request: AppSettings) {
        const url = `${this.endpointPath}`;
        return this.http.put<AppSettings>(url, request).then((response: any) => response.data);
    }
}

export const appSettingsService = new AppSettingsService(API_ENDPOINTS.APP_SETTINGS);