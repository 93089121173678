import { Button } from "primereact/button";

interface TableHeaderProps {
  header: string;
  showAddButton: boolean;
  onClickAdd?: () => void;
}

export function TableHeader({
  header,
  showAddButton,
  onClickAdd,
}: TableHeaderProps) {
  return (
    <div className="flex justify-content-between">
      <div className="text-xl font-bold">{header}</div>
      {showAddButton && (
        <Button label="Dodaj" icon="pi pi-plus" onClick={onClickAdd} />
      )}
    </div>
  );
}
