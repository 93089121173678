import { useMemo } from "react";
import {
  Field,
  FastField,
  FormikContextType,
  FieldMetaProps,
  FieldInputProps,
  ErrorMessage,
} from "formik";
import { Calendar, CalendarProps } from "primereact/calendar";
import { classNames } from "primereact/utils";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import * as Yup from "yup";

interface FormikCalendarProps extends CalendarProps {
  label: string;
  name: string;
  mode?: "single" | "multiple" | "range" | undefined;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  isRequired?: boolean;
}

function FormikCalendar({
  label,
  name,
  mode = "single",
  validationSchema,
  isIndependent = false,
  isRequired = undefined,
  ...rest
}: FormikCalendarProps) {
  const Component = useMemo(
    () => (isIndependent ? FastField : Field),
    [isIndependent]
  );

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
        forceIsRequired={isRequired}>
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<any>;
        }) => {
          return (
            <Calendar
              id={name}
              className={classNames({
                "w-full": true,
                "p-invalid": meta.error,
              })}
              placeholder={label}
              value={field.value}
              onBlur={() => form.setFieldTouched(name, true)}
              onChange={(e) => {
                e.originalEvent?.stopPropagation();
                form.setFieldValue(name, e.target.value);
              }}
              selectionMode={mode}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export default FormikCalendar;
