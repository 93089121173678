import { useMutation, useQuery } from 'react-query';
import { Rider } from './models/rider.model';
import { ridersService } from '../services/riders.service';

export function useRidersQuery() {
    return useQuery<Rider[]>(["riders"], () =>
        ridersService.getAllActive());
}

export function useActiveRidersAsSelectOptionQuery() {
    return useQuery(
        ["riders", "riders-options"],
        () => ridersService.getAllActiveAsOptions()
    )
}

export function useAddRiderMutation() {
    return useMutation((request: Rider) => ridersService.add(request));
}

export function useEditRiderMutation() {
    return useMutation((request: Rider) => ridersService.edit(request));
}

export function useDeleteRiderMutation() {
    return useMutation((id: number) => ridersService.delete(id));
}