import { useState, useMemo } from "react";
import { MenuItem } from "./bar-navigation";
import { BottomBarNavigationMobileItem } from "./bottom-bar-navigation-item-mobile";
import { useWindowSize } from "../../hooks/use-window-size";
import { Sidebar } from "primereact/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

const pixelsPerItem = 125;

export function BottomBarNavigationMobileItems({
  menuItems,
}: {
  menuItems: MenuItem[];
}) {
  const windowSize = useWindowSize();
  const [rightBarVisible, setRightBarVisible] = useState(false);

  const itemsCounter = useMemo(
    () =>
      menuItems.filter((_, i) => pixelsPerItem * (i * 1) < windowSize.width)
        .length,
    [menuItems, windowSize.width]
  );

  return (
    <>
      <ul className="list-none px-3 flex justify-content-between mt-2">
        {menuItems
          .filter((_, i) => pixelsPerItem * (i * 1) < windowSize.width)
          .map((x, i) => (
            <BottomBarNavigationMobileItem
              to={x.to}
              label={x.label}
              icon={x.icon}
              key={x.label}
            />
          ))}

        {itemsCounter !== menuItems.length && (
          <li onClick={() => setRightBarVisible(true)}>
            <div>
              <FontAwesomeIcon
                className="fa-2xl py-1 px-2"
                icon={faEllipsis}
              />
            </div>
          </li>
        )}
        <Sidebar
          className="sidebar-mobile-nav"
          visible={rightBarVisible}
          onHide={() => setRightBarVisible(false)}
          position="right"
          ariaCloseLabel="true"
          style={{ width: `calc(100% / ${itemsCounter + 1})` }}>
          <div className="w-full h-full flex flex-column justify-content-center align-items-center gap-4 list-none">
            {menuItems
              .filter((_, i) => pixelsPerItem * (i * 1) >= windowSize.width)
              .map((x, i) => (
                <div
                  onClick={() => setRightBarVisible(false)}
                  key={x.label}>
                  <BottomBarNavigationMobileItem
                    to={x.to}
                    label={x.label}
                    icon={x.icon}
                    key={x.label}
                    isSituatedOnSidebar
                  />
                </div>
              ))}
          </div>
        </Sidebar>
      </ul>
    </>
  );
}
