import { useEffect, useState } from 'react';

export function useWindowSize() {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    sm: window.innerWidth <= 640,
    lg: window.innerWidth >= 1024,
  });

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
