import axios from "axios";
import { AxiosRequestHeaders } from 'axios';
import { handleDates } from "../utils/serialize-date-helpers";
import { LoggedUserInfo } from "../queries/models/_shared/logged-user-info";
import { AUTH_USER_INFO } from "../constants/auth-user-info.constant";

const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


request.interceptors.request.use(
    (config) => {
        const loggedUser = getLoggedUser();
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${loggedUser ? loggedUser.accessToken : ""}`,
        } as AxiosRequestHeaders;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


request.interceptors.response.use((response) => {
    handleDates(response.data);
    return response;
});

request.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.href = "/";
        }
        return Promise.reject(error.response);
    }
);

request.interceptors.response.use((response) => {
    const newToken = response.headers["x-refresh-token"];


    if (newToken) {
        const user = getLoggedUser();

        if (user) {
            user.accessToken = newToken;
            setLoggedUser(user);
        }
    }

    return response;
}
);

const getLoggedUser = (): LoggedUserInfo => {

    const valueString = localStorage.getItem(AUTH_USER_INFO);
    return valueString ? JSON.parse(valueString) : null;
}

const setLoggedUser = (info: LoggedUserInfo): void => {
    localStorage.setItem(AUTH_USER_INFO, JSON.stringify(info))
}

export default request;