import { BaseSyntheticEvent, useCallback, useMemo, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Ride } from "../../queries/models/ride.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { useRidesQuery } from "../../queries/rides.query";
import { useRidesReportQuery } from "../../queries/reporting.query";
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  endOfMonth,
  getDate,
  getUnixTime,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";

export function Reports() {
  const queryClient = useQueryClient();
  const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
  const [toDate, setToDate] = useState(endOfMonth(new Date()));
  const ridesReportQuery = useRidesReportQuery(fromDate, toDate);

  const [selectedRiders, setSelectedRiders] = useState<string[]>([]);
  const [selectedHorses, setSelectedHorses] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const allRiders = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.rider)
        .select((r) => ({ label: r.rider, value: r.rider }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const allHorses = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.horse)
        .select((r) => ({ label: r.horse, value: r.horse }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const allUsers = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.user)
        .select((r) => ({ label: r.user, value: r.user }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const filteredData = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .where((r) =>
          selectedRiders.length > 0 ? selectedRiders.includes(r.rider) : true
        )
        .where((r) =>
          selectedHorses.length > 0 ? selectedHorses.includes(r.horse) : true
        )
        .where((r) =>
          selectedUsers.length > 0 ? selectedUsers.includes(r.user) : true
        )
        .toArray() ?? []
    );
  }, [ridesReportQuery.data, selectedRiders, selectedHorses, selectedUsers]);

  const filteredDataGroupdByDate = useMemo(() => {
    return (
      Enumerable.from(filteredData)
        .groupBy((r) => getUnixTime(startOfDay(r.date)) * 1000)
        .orderBy((g) => g.key())
        .select((g) => {
          return {
            date: new Date(g.key()),
            value: g.getSource().length,
            rides: g.getSource(),
          };
        })
        .toArray() ?? []
    );
  }, [filteredData]);

  const filteredDataGroupdByHorse = useMemo(() => {
    return (
      Enumerable.from(filteredData)
        .groupBy((r) => r.horse)
        .orderBy((g) => g.key())
        .select((g) => {
          return {
            label: g.key(),
            value: g.getSource().length,
            rides: g.getSource(),
          };
        })
        .toArray() ?? []
    );
  }, [filteredData]);

  const avgDayCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    return ((filteredData?.length ?? 0) / daysDiff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const avgWeekCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    const diff = daysDiff / 7;
    return ((filteredData?.length ?? 0) / diff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const avgMonthCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    const diff = daysDiff / 30;
    return ((filteredData?.length ?? 0) / diff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const chartOptions = useMemo(() => {
    let ridesDateData = filteredDataGroupdByDate.map(
      (z) =>
        [getUnixTime(new Date(z.date)) * 1000, z.value] satisfies [
          number,
          number
        ]
    );

    const ridesSerie = {
      data: ridesDateData,
      name: "Dzienne jazdy",
      type: "bar",
      smooth: true,
      symbol: "none",
      animation: true,
      label: {
        show: true,
        position: "top",
      },
      areaStyle: {
        animation: true,
      },
    };

    return {
      grid: { top: 8, right: 16, bottom: 64, left: 32 },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [ridesSerie],
      tooltip: {
        show: true,
        trigger: "axis",
        //formatter: formatter,
      },
    } as EChartsOption;
  }, [filteredDataGroupdByDate]);

  const horseChartOptions = useMemo(() => {
    let ridesDateData = filteredDataGroupdByHorse.map(
      (z) => [z.label, z.value] satisfies [string, number]
    );

    const ridesSerie = {
      data: ridesDateData,
      name: "Konie jazdy",
      type: "bar",
      smooth: true,
      symbol: "none",
      animation: true,
      label: {
        show: true,
        position: "top",
      },
      areaStyle: {
        animation: true,
      },
    };

    return {
      grid: { top: 8, right: 16, bottom: 60, left: 32 },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [ridesSerie],
      tooltip: {
        show: true,
        trigger: "axis",
        //formatter: formatter,
      },
    } as EChartsOption;
  }, [filteredDataGroupdByHorse]);

  return (
    <div className="w-full">
      <div className="flex flex-row m-2 gap-2">
        <Calendar
          className="w-6"
          locale="pl"
          dateFormat="dd MM yy"
          onChange={(x) => setFromDate(x.value as Date)}
          value={fromDate}
        />

        <Calendar
          className="w-6"
          locale="pl"
          dateFormat="dd MM yy"
          onChange={(x) => setToDate(x.value as Date)}
          value={toDate}
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedRiders}
          onChange={(e) => setSelectedRiders(e.value)}
          options={allRiders}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj jeźdźców"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedHorses}
          onChange={(e) => setSelectedHorses(e.value)}
          options={allHorses}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj konie"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedUsers}
          onChange={(e) => setSelectedUsers(e.value)}
          options={allUsers}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj instruktorów"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>

      <div>
        <ReactECharts
          className={"w-full h-12rem p-0 m-0"}
          option={horseChartOptions}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>

      <div>
        <ReactECharts
          className={"w-full h-12rem p-0 m-0"}
          option={chartOptions}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>

      <div>
        <table className="table-auto text-xl">
          <tbody>
            <tr>
              <td>Średnia dzień: </td>
              <td className="font-bold pl-4">{avgDayCount}</td>
            </tr>
            <tr>
              <td>Średnia tydzień: </td>
              <td className="font-bold pl-4">{avgWeekCount}</td>
            </tr>
            <tr>
              <td>Średnia miesiąc: </td>
              <td className="font-bold pl-4">{avgMonthCount}</td>
            </tr>
            <tr>
              <td>Total: </td>
              <td className="font-bold pl-4">{filteredData.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
