import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { SelectItem } from "primereact/selectitem";
import { Rider } from "../queries/models/rider.model";

class RidersService extends BaseService {

    async getAllActive(): Promise<Rider[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Rider[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }

    async add(request: Rider) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<any>(url, request).then((response: any) => response.data);
    }
    async edit(request: Rider) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<any>(url, request).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const ridersService = new RidersService(API_ENDPOINTS.RIDERS);