import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Ride } from "../queries/models/ride.model";

class RidesService extends BaseService {

    async getAllActive(): Promise<Ride[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Ride[]>(url).then((response: any) => response.data);
    }

    async getAllActiveToday(): Promise<Ride[]> {
        const url = `${this.endpointPath}/active/today`;
        return this.http.get<Ride[]>(url).then((response: any) => response.data);
    }

    async getAllActiveByRider(id: number): Promise<Ride[]> {
        const url = `${this.endpointPath}/rider/${id}`;
        return this.http.get<Ride[]>(url).then((response: any) => response.data);
    }

    async getLastRiderHorse(id: number): Promise<number | undefined> {
        const url = `${this.endpointPath}/rider/${id}/last-horse`;
        return this.http.get<number | undefined>(url).then((response: any) => response.data);
    }

    async getLastRiderUser(id: number): Promise<number | undefined> {
        const url = `${this.endpointPath}/rider/${id}/last-user`;
        return this.http.get<number | undefined>(url).then((response: any) => response.data);
    }

    async getLastRiderRideType(id: number): Promise<number | undefined> {
        const url = `${this.endpointPath}/rider/${id}/last-ride-type`;
        return this.http.get<number | undefined>(url).then((response: any) => response.data);
    }

    async getAllByDateRangeActive(dateFrom: Date, dateTo: Date): Promise<Ride[]> {
        const url = `${this.endpointPath}/active/${dateFrom.toISOString()}/${dateTo.toISOString()}`;
        return this.http.get<Ride[]>(url).then((response: any) => response.data);
    }

    async getAllByDateActive(dateFrom: Date): Promise<Ride[]> {
        const url = `${this.endpointPath}/active/${dateFrom.toISOString()}`;
        return this.http.get<Ride[]>(url).then((response: any) => response.data);
    }

    async add(request: Ride) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<any>(url, request).then((response: any) => response.data);
    }
    async edit(request: Ride) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<any>(url, request).then((response: any) => response.data);
    }
    async payDate(date: Date) {
        const url = `${this.endpointPath}/pay-date/${date.toISOString()}`;
        return this.http.put<any>(url).then((response: any) => response.data);
    }
    async pay(id: number) {
        const url = `${this.endpointPath}/pay/${id}`;
        return this.http.put<any>(url).then((response: any) => response.data);
    }
    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
    async deleteSchedule(id: number) {
        const url = `${this.endpointPath}/delete-schedule/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
    async deleteInstance(scheduleId: number, date: Date) {
        const url = `${this.endpointPath}/delete-scheduled-instance/${scheduleId}/${date.toISOString()}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const ridesService = new RidesService(API_ENDPOINTS.RIDES);