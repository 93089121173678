import { useMemo } from "react";
import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldMetaProps,
  FieldInputProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { InputSwitch, InputSwitchProps } from "primereact/inputswitch";
import * as Yup from "yup";

export interface FormikInputSwitchPros extends InputSwitchProps {
  label: string;
  name: string;
  checked: boolean;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
}

function FormikInputSwitch({
  label,
  name,
  checked = false,
  validationSchema,
  isIndependent = false,
  ...rest
}: FormikInputSwitchPros) {
  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);

  return (
    <div className="h-full flex flex-column">
      <FormInputLabel nameFor={name} validationSchema={validationSchema}>
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<any>;
        }) => {
          return (
            <InputSwitch
              id={name}
              checked={field.value}
              className={classNames({
                "p-invalid": meta.error && meta.touched,
              })}
              onBlur={(e) => form.setFieldTouched(name, true)}
              onChange={(e) => {
                form.setFieldValue(name, e.value);
              }}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default FormikInputSwitch;
