import { useEffect, useMemo, useState } from "react";
import {
  useGetUserByTokenQuery,
  useResetPasswordMutation,
} from "../../queries/models/_shared/auth.query";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../../components/ui/toast-context-provider";
import { classNames } from "primereact/utils";

export function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("t");
  const toast = useToast();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmed, setPasswordConfirmed] = useState<string>();

  const query = useGetUserByTokenQuery(token!);
  const mutation = useResetPasswordMutation();
  const navigate = useNavigate();

  const isPasswordMatched = useMemo(
    () =>
      passwordConfirmed === undefined ||
      passwordConfirmed === null ||
      password === passwordConfirmed,
    [password, passwordConfirmed]
  );
  const isFormValid = useMemo(
    () => password && passwordConfirmed && isPasswordMatched,
    [password, passwordConfirmed, isPasswordMatched]
  );

  useEffect(() => {
    if (query.isError) {
      toast.current?.show({
        severity: "error",
        detail: "Token expired!",
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [navigate, query.data, query.isError, toast]);

  function executeReset() {
    setIsLoading(true);
    mutation.mutate(
      {
        password: password!,
        token: token!,
        userId: query.data?.id!,
      },
      {
        onSuccess: (response) => {
          toast.current?.show({
            severity: "success",
            detail: "Reset success, now login with new password.",
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        },
        onError: (error) => {
          toast.current?.show({
            severity: "error",
            detail: error?.toString(),
          });
          console.error(error);
        },
        onSettled: (x, y, z, c) => {
          setIsLoading(false);
        },
      }
    );
  }

  return (
    <div className="surface-300">
      <div className="flex justify-content-center align-items-center h-screen">
        <Card className="w-20rem border-3 border-primary border-round-2xl">
          <h2 className="mt-1 text-center">Reset password form</h2>
          {query.data && (
            <span className="p-float-label mb-4">
              {`Hello ${query.data.username}!`}
            </span>
          )}
          {query.isFetching && (
            <span className="p-float-label">Loading data..</span>
          )}
          <span className="p-float-label  mb-4">
            <InputText
              id="password"
              className="w-full"
              type={"password"}
              value={password ?? ""}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">New password:</label>
          </span>

          <span className="p-float-label">
            <InputText
              id="passwordConfirmed"
              className={classNames(
                { "p-invalid": !isPasswordMatched },
                "w-full"
              )}
              type={"password"}
              value={passwordConfirmed ?? ""}
              onChange={(e) => setPasswordConfirmed(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? executeReset() : {})}
            />
            <label htmlFor="passwordConfirmed">Confirm password:</label>
          </span>
          {!isPasswordMatched && (
            <div className=" text-right text-red-600">Password not match</div>
          )}

          <Button
            label="Send request"
            loading={isLoading}
            className="bg-primary mt-4 w-full"
            onClick={executeReset}
            disabled={!isFormValid}
          />
        </Card>
      </div>
    </div>
  );
}
