import { Button } from "primereact/button";
import React, { ReactNode, useEffect, useState } from "react";
import "./mobile-modal-styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "primereact/utils";

export interface CustomModalProps {
  isOpen: boolean;
  header: string;
  onClose(): void;
  confirmation?: boolean;
  onConfirm?(): void;
  body?: ReactNode | string;
  centered?: boolean;
  justified?: boolean;
  width?: string;
  height?: string;
}

export function CustomModal({
  isOpen,
  onClose,
  onConfirm,
  body,
  centered,
  justified,
  header: title,
  confirmation = false,
  width,
  height,
}: CustomModalProps) {
  const [isVisible, setIsVisible] = useState<boolean>();

  useEffect(() => {
    setIsVisible(true);
  }, [isOpen]);

  const closeModal = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const backgroundClick = (e: any) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      {isOpen && (
        <div
          onClick={backgroundClick}
          className={classNames(
            "bg-dialog-blur card-wrapper",
            isVisible ? "grey-in" : "grey-out",
            centered ? "centered" : "",
            justified ? "justified" : ""
          )}
        >
          <div
            style={{ width: width, height: height }}
            className={classNames(
              "card",
              isVisible ? "slide-up" : "slide-down"
            )}
          >
            <div className="header">
              <div className="title">{title}</div>
              <div className="close-btn cursor-pointer">
                <FontAwesomeIcon icon={faXmark} onClick={closeModal} />
              </div>
            </div>
            <div className="body">{body}</div>
            {confirmation && (
              <div className="footer">
                <Button onClick={closeModal}>Anuluj</Button>
                <Button onClick={onConfirm}>Potwierdź</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
