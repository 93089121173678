import { useMutation, useQuery } from 'react-query';
import { RideType } from './models/ride-type.model';
import { rideTypesService } from '../services/ride-types.service';

export function useRideTypesQuery() {
    return useQuery<RideType[]>(["ride-types"], () =>
        rideTypesService.getAllActive());
}

export function useActiveRideTypesAsSelectOptionQuery() {
    return useQuery(
        ["ride-types", "ride-types-options"],
        () => rideTypesService.getAllActiveAsOptions()
    )
}

export function useAddRideTypeMutation() {
    return useMutation((request: RideType) => rideTypesService.add(request));
}

export function useEditRideTypeMutation() {
    return useMutation((request: RideType) => rideTypesService.edit(request));
}

export function useDeleteRideTypeMutation() {
    return useMutation((id: number) => rideTypesService.delete(id));
}