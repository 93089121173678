import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { ridesService } from '../services/rides.service';
import { Ride } from './models/ride.model';

export function useRidesQuery() {
    return useQuery<Ride[]>(["rides"], () =>
        ridesService.getAllActive());
}
export function useTodayRidesQuery() {
    return useQuery<Ride[]>(["rides", "today-rides"], () =>
        ridesService.getAllActiveToday());
}

export function useRidesByDateRangeQuery(dateFrom: Date, dateTo: Date) {
    const queryOptions: UseQueryOptions<Ride[]> = {
        enabled: !(!dateFrom || !dateTo)
    }

    return useQuery<Ride[]>(
        ["rides", dateFrom, dateTo],
        () => ridesService.getAllByDateRangeActive(dateFrom, dateTo),
        queryOptions
    )
}

export function useRidesByDateQuery(date: Date) {
    const queryOptions: UseQueryOptions<Ride[]> = {
        enabled: !(!date)
    }

    return useQuery<Ride[]>(
        ["rides", date],
        () => ridesService.getAllByDateActive(date),
        queryOptions
    )
}

export function useRidesByRiderQuery(id: number) {
    const queryOptions: UseQueryOptions<Ride[]> = {
        enabled: !(!id)
    }

    return useQuery<Ride[]>(
        ["rides", id],
        () => ridesService.getAllActiveByRider(id),
        queryOptions
    )
}

export function useAddRideMutation() {
    return useMutation((request: Ride) => ridesService.add(request));
}

export function useEditRideMutation() {
    return useMutation((request: Ride) => ridesService.edit(request));
}
export function usePayRideMutation() {
    return useMutation((id: number) => ridesService.pay(id));
}
export function usePayDateMutation() {
    return useMutation((date: Date) => ridesService.payDate(date));
}

export function useDeleteRideMutation() {
    return useMutation((id: number) => ridesService.delete(id));
}
export function useDeleteRideScheduleMutation() {
    return useMutation((id: number) => ridesService.deleteSchedule(id));
}
export function useDeleteRideInstanceMutation() {
    return useMutation((ride: Ride) => ridesService.deleteInstance(ride.scheduledRideId!, ride.date));
}