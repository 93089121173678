import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Rider } from "../queries/models/rider.model";
import { RideReportItem } from "../queries/models/ride-report.model";

class ReportingService extends BaseService {

    async getRidesReport(from: Date, to: Date): Promise<RideReportItem[]> {
        const url = `${this.endpointPath}/rides/${from.toISOString()}/${to.toISOString()}`;
        return this.http.get<RideReportItem[]>(url).then((response: any) => response.data);
    }
}

export const reportingService = new ReportingService(API_ENDPOINTS.REPORTING);