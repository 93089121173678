import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { confirmDialog } from "primereact/confirmdialog";
import { phoneRegExp } from "../../utils/phoneRegExp";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { authService } from "../../services/auth.service";
import { Horse } from "../../queries/models/horse.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";

export interface HorseEditFormProps {
  horse: Horse;
  isSubmitting?: boolean;
  onSave: (form: Horse) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function HorseForm({
  horse,
  onSave,
  onCancel,
  onDelete,
}: HorseEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const initialValues: Horse = {
    id: horse?.id ?? 0,
    name: horse?.name ?? "",
    comment: horse?.comment ?? "",
    shortName: horse?.shortName ?? "",
    skills: [],
    rides: [],
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    shortName: Yup.string(),
    comment: Yup.string().optional().nullable(),
  });

  const onSubmit = useCallback(
    (values: Horse) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={horse?.id ? horse.name : "Dodaj nowego konia"}
                showDeleteButton={hasWriteAccess && !!horse.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Nazwa"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Skrót"
                    name="shortName"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
