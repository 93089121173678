import { useMutation, useQuery } from 'react-query';
import { Horse } from './models/horse.model';
import { horsesService } from '../services/horses.service';

export function useHorsesQuery() {
    return useQuery<Horse[]>(["horses"], () =>
        horsesService.getAllActive());
}

export function useActiveHorsesAsSelectOptionQuery() {
    return useQuery(
        ["horses", "horses-options"],
        () => horsesService.getAllActiveAsOptions()
    )
}

export function useAddHorseMutation() {
    return useMutation((request: Horse) => horsesService.add(request));
}

export function useEditHorseMutation() {
    return useMutation((request: Horse) => horsesService.edit(request));
}

export function useDeleteHorseMutation() {
    return useMutation((id: number) => horsesService.delete(id));
}