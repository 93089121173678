import { useCallback, useMemo } from "react";
import { User } from "../../queries/models/user.model";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import { AddOrEditUser } from "../../queries/models/form-user.model";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { phoneRegExp } from "../../utils/phoneRegExp";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";

export interface UserEditFormProps {
  user: User;
  isSubmitting?: boolean;
  onSave: (form: AddOrEditUser) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function UserForm({
  user,
  onSave,
  onCancel,
  onDelete,
}: UserEditFormProps) {
  const { lg } = useWindowSize() || {};
  const toast = useToast();
  const hasWriteAccess = true;

  const initialValues: AddOrEditUser = {
    username: user?.username ?? "",
    name: user?.name ?? "",
    phone: user?.phone ?? "",
    email: user?.email ?? "",
    shortName: user?.shortName ?? "",
    password: "" ?? undefined,
    passwordRepeat: "" ?? undefined,
    id: user?.id ?? 0,
    isAdmin: user?.isAdmin ?? false,
  };
  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
    name: Yup.string().optional(),
    shortName: Yup.string().optional(),
    phone: Yup.string().matches(phoneRegExp, "Phone number format invalid"),
    email: Yup.string().optional().email("Email format invalid"),
    isAdmin: Yup.boolean().optional(),
    password: user?.id
      ? Yup.string().optional().min(8, "Must be 8 characters or more")
      : Yup.string()
          .required("Required")
          .min(8, "Must be 8 characters or more"),
    passwordRepeat: user?.id
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password")], "Passwords must match")
      : Yup.string()
          .required("Required")
          .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const onSubmit = useCallback(
    (values: AddOrEditUser) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={user?.id ? user.username : "Add new user"}
                showDeleteButton={hasWriteAccess && !!user.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Username"
                    name="username"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Skrót"
                    name="shortName"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label="Full name"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label="User phone"
                    name="phone"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Email"
                    name="email"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputSwitch
                    checked={false}
                    label="Administrator"
                    name="isAdmin"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label="Password"
                    type="password"
                    name="password"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label="Repeat password"
                    type="password"
                    name="passwordRepeat"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess || !formik.values.password}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
