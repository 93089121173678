import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import { Users } from "./pages/Users/UsersPage";
import { Login } from "./pages/Authentication/Login";
import { ContentPage } from "./pages/ContentPage";
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { ToastContextProvider } from "./components/ui/toast-context-provider";
import { useWindowSize } from "./hooks/use-window-size";
import { ForgotPassword } from "./pages/Authentication/ForgotPassword";
import { ResetPassword } from "./pages/Authentication/ResetPassword";
import { addLocale } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup } from "primereact/confirmpopup";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HorseForm } from "./pages/Horses/HorsesForm";
import { Horses } from "./pages/Horses/HorsesPage";
import { Riders } from "./pages/Riders/RidersPage";
import { SkillLevels } from "./pages/SkillLevels/SkillLevelsPage";
import { Reports } from "./pages/Reports/ReportsPage";
import { Plan } from "./pages/Plan/PlanPage";
import { VariablesTabs } from "./pages/Variables/VariablesPage";

addLocale("pl", {
  firstDayOfWeek: 1,
  dayNames: [
    "niedziela",
    "poniedziałek",
    "wtorek",
    "środa",
    "czwartek",
    "piątek",
    "sobota",
  ],
  dayNamesMin: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
  dayNamesShort: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
  monthNames: [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "paźdzeirnik",
    "listopad",
    "grudzień",
  ],
  today: "Dziś",
  clear: "Wyczyść",
});

function App() {
  const toast = useRef<Toast>(null);
  const { lg } = useWindowSize();

  useEffect(() => {
    let isDarkTheme = localStorage.getItem("darkTheme") ?? false;

    if (isDarkTheme === undefined) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        isDarkTheme = true;
      }
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches
      ) {
        isDarkTheme = false;
      }
    }

    if (isDarkTheme) {
      document.body.classList.remove("theme-light");
      document.body.classList.add("theme-dark");
    } else {
      document.body.classList.add("theme-light");
      document.body.classList.remove("theme-dark");
    }
  }, []);

  return (
    <div className="h-full">
      <ConfirmDialog />
      <ConfirmPopup />
      <Toast ref={toast} />
      <ToastContextProvider toastRef={toast}>
        <DndProvider backend={lg ? HTML5Backend : TouchBackend}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="app" element={<ContentPage />}>
              <Route path="users/:id?" element={<Users />} />
              <Route path="riders/:id?" element={<Riders />} />
              <Route path="horses/:id?" element={<Horses />} />
              <Route path="variables" element={<VariablesTabs />} />
              <Route path="reports" element={<Reports />} />
              <Route path="plan" element={<Plan />} />
            </Route>
          </Routes>
        </DndProvider>
      </ToastContextProvider>
    </div>
  );
}

export default App;
