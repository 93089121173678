import { addMinutes, format } from "date-fns";
import { Ride } from "../../queries/models/ride.model";
import { Button } from "primereact/button";
import { useMemo } from "react";
import { isWithinInterval } from "date-fns/fp";
import { PlannedRide } from "./PlannedRide";
import { authService } from "../../services/auth.service";

export interface PlanRowProps {
  rides: Ride[];
  date: Date;
  onAddNewRide: (date: Date) => void;
  onEditRide: (ride: Ride) => void;
  shortNames?: boolean;
  showInstructor?: boolean;
}

export function PlanRow({
  rides,
  date,
  shortNames = true,
  showInstructor = true,
  onAddNewRide,
  onEditRide,
}: PlanRowProps) {
  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);

  const dateRides = useMemo(() => {
    const validRange = {
      start: date,
      end: addMinutes(date, 59),
    };

    const result = rides.filter((ride) => {
      return isWithinInterval(validRange, ride.date);
    });

    return result;
  }, [rides, date, shortNames, showInstructor]);

  return (
    <div className="w-full flex shadow-1 mt-1 p-2">
      <div className="pr-2 align-self-center">
        <span className="text-xl">{format(date, "HH:mm")}</span>
      </div>
      <div className="w-full flex align-self-center flex-wrap">
        {dateRides.map((ride) => (
          <PlannedRide
            key={`${ride.id}${ride.scheduledRideId}${ride.riderId}`}
            ride={ride}
            shortNames={shortNames}
            showInstructor={showInstructor}
            onSelected={() => onEditRide(ride)}
          />
        ))}
      </div>
      <div className="align-self-center text-right">
        {isAdmin && (
          <Button
            icon="pi pi-plus pull-right"
            onClick={() => onAddNewRide(date)}
          />
        )}
      </div>
    </div>
  );
}
